/* @import url("https://fonts.googleapis.com/css2?family=Arvo&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Quintessential&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,700;1,400;1,700&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  /* background-color: #000b1c; */
  /* background-image: url("../public/images/bg1c.jpg"); */
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  color: white;
}

body {
  background-color: #000b1c;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* font-family: "Arvo", serif; */
  /* font-family: "Quintessential", cursive; */
  font-family: "Poppins", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* .base-page-size {
  width: 100%;
  min-height: 100vh;
} */

.explore-page-hero {
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .content {
  position: relative;
  display: flex;
  flex-direction: column;
} */
.title-text {
  font-family: var(--title-font);
  font-size: 85px;
  margin: 0;
}

.sub-text {
  margin: 0;
  margin-bottom: 18px;
  text-align: center;
  max-width: 700px;
}

.nav {
  padding: 18px;
  position: fixed;
  z-index: 1;
  display: flex;
  top: 0;
  background-color: #000b1c85;
  height: 4rem;
  width: 100%;
  box-shadow: 0px 0px 2px gray;
  color: white;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
.nav .mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  background-color: hsl(216, 100%, 4%);
  margin-top: 4rem;
  color: white;
  box-shadow: 0 0px 1px gray;
  padding: 0 2rem 0 2rem;
}

.menu-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-content-mobile .menu-toggle .open {
  font-size: 2.8rem;
}
.mobile-blur {
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000b1c75;
}
.nav h3 {
  margin-top: -0.6rem;
}
.nav-content {
  display: flex;
  color: white;
  list-style: none;
  align-items: center;
  margin-left: auto;
}
.nav-content .register-btn {
  position: relative;
  background-color: gray;
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(255, 255, 255, 0.534),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    14.17px 8.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
  padding: 0.5rem 0.9rem;
  border-radius: 2rem;
}
.nav-content li {
  margin-right: 2rem;
  font-size: 0.8rem;
}
.nav-content-mobile {
  display: none;
  color: white;
  list-style: none;
  align-items: center;
  margin-left: auto;
}

.nav-content-mobile li {
  font-size: 1.2rem;
  padding: 1.5rem 0rem;
}
.nav-content-mobile .register-btn {
  position: relative;
  width: 100%;
  background-color: gray;
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(255, 255, 255, 0.534),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    14.17px 8.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
  padding: 0.7rem 4rem;
  text-align: center;
  border-radius: 2rem;
}
.nav-content-mobile .open {
  font-size: 2rem;
}
.footer {
  padding: 18px;
  position: fixed;
  z-index: 1;
  display: flex;
  bottom: 0;
  background-color: #000b1c85;
  height: 1rem;
  width: 100%;
  color: white;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
.footer-content {
  display: flex;
  color: white;
  list-style: none;
  align-items: center;
  margin-left: auto;
}
.footer-content li {
  margin-right: 2rem;
  font-size: 0.7rem;
}

.back-btn {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.4rem 18px;
  border-radius: 2rem;
  position: relative;
  background-color: gray;
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(255, 255, 255, 0.534),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    14.17px 8.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 18px;
  font-family: var(--title-font);
  cursor: pointer;
}
.hero_btn {
  position: relative;
  margin-top: 2rem;
  width: 100%;
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: center;
}
.hero-back-btn {
  display: inline-block;
  padding: 10px 10px;
  border-radius: 3rem;
  position: relative;
  background-color: white;
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(0, 0, 0, 0.15),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    24.17px 18.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-left: -3rem;
}
.hero-back-toggle {
  display: inline-block;
  padding: 4px 30px;
  border-radius: 3rem;
  position: absolute;
  background-color: white;
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(0, 0, 0, 0.15),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    24.17px 18.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.nav-link {
  margin-right: 40px;
  font-size: 28px;
  font-family: var(--title-font);
  cursor: pointer;
}

.bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

:root {
  --level-one: translateZ(3rem);
  --level-two: translateZ(6rem);
  --level-three: translateZ(9rem);

  --fw-normal: 400;
  --fw-bold: 700;

  --clr: #b7c9e5;
}

.card {
  position: relative;
}

.card__content {
  text-align: center;
  position: relative;
  padding: 5em 5em;
  transition: transform 6s;
  transform-style: preserve-3d;
  height: 23rem;
  width: 50rem;
}
.card__front,
.card__back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em 3em;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  display: grid;
  align-content: center;
}

.card__front {
  border-radius: 3rem;
  margin-top: 1rem;
  background-color: hsl(216, 100%, 8%);
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(0, 0, 0, 0.15),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    24.17px 18.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
}

.card__front::before {
  content: "";
  position: absolute;
  --spacer: 1em;
  top: var(--spacer);
  bottom: var(--spacer);
  left: var(--spacer);
  right: var(--spacer);
  transform: var(--level-one);
}

.card__title {
  font-size: 3rem;
  transform: var(--level-three);
  order: 0;
}

.card__subtitle {
  transform: var(--level-two);
  font-size: 1rem;
  opacity: 0.7;
}

.card__body {
  transform: var(--level-two);
  font-weight: var(fw-normal);
  font-size: 1.5rem;
  line-height: 1.6;
}

.card__back {
  transform: rotateY(0.5turn);
  color: white;
  border-radius: 3rem;
  margin-top: 1rem;
  background-color: hsl(216, 100%, 8%);
  box-shadow: inset 8.06px 6.25px 14.31px -1.79px rgba(0, 0, 0, 0.15),
    inset -12.09px -9.38px 14.31px -1.79px rgba(0, 0, 0, 0.3),
    24.17px 18.76px 10.73px -5.37px rgba(0, 0, 0, 0.6);
}

.underline {
  box-shadow: -0.01px 4px 0 white;
}

.hero_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .card:hover .card__content {
  transform: rotateY(0.5turn);
} */

.card__content.is-flipped {
  transform: rotateY(180deg);
}

@media screen and (max-width: 767px) {
  .card__content {
    height: 25rem;
    width: 20rem;
    margin-top: 1.5rem;
  }
  .card__title {
    font-size: 2rem;
  }

  .card__subtitle {
    font-size: 1rem;
  }
  .nav-content {
    display: none;
  }
  .nav-content-mobile {
    display: flex;
  }
  .nav {
    box-shadow: 0px 0px 5px gray;
  }
}
